import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import "./Result.css";
import SearchBox from "Components/SearchBox/SearchBox";
import { Col, Container, Row } from "react-bootstrap";
import React, { Fragment } from "react";
import CardSkeleton from "Components/CardSkeleton/CardSkeleton";
import { Helmet } from "react-helmet-async";

const Result = () => {
  const { name, rawRange } = useParams();
  const [whiskies, setWhiskies] = useState([]);
  const [isLoading, setLoading] = useState(true);
  let minPrc, maxPrc;

  const money = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  if (!name) {
    const pattern = /\d*-\d*/;

    if (pattern.test(rawRange)) {
      let mm = rawRange.split("-");
      maxPrc = mm[1];
      minPrc = mm[0];

      if (Number(minPrc) > Number(maxPrc)) {
        maxPrc = mm[0];
        minPrc = mm[1];
      }
    } else {
      minPrc = 1;
      maxPrc = 300;
    }
  }

  const fetchData = (_name, _min, _max) => {
    let url;
    if (_name) {
      url = `https://us-central1-whisky-go.cloudfunctions.net/whisky-api?name=${_name}`;
    } else {
      url = `https://us-central1-whisky-go.cloudfunctions.net/whisky-api?minprice=${_min}&maxprice=${_max}`;
    }

    setLoading(true);
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setWhiskies(data);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData(name, minPrc, maxPrc);
  }, [name, minPrc, maxPrc]);

  return (
    <Fragment>
      <Helmet>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Container>
        <Row className="spc-top-search">
          <Col
            className="text-center"
            xs={{ span: 6, offset: 3 }}
            sm={{ span: 3, offset: 0 }}
            md={{ span: 2, offset: 0 }}
            lg={{ span: 2, offset: 0 }}
            xl={{ span: 1, offset: 2 }}
            xxl={{ span: 1, offset: 2 }}
          >
            <a href="/">
              <img
                src="/logo.svg"
                alt="Whisky Go - Single malt, Bourbon, Rye, Blended e Grain! Encontre os melhores preços pra comprar whisky!"
                title="Whisk Go - Single malt, Bourbon, Rye, Blended e Grain! Encontre os melhores preços pra comprar whisky!"
                className="logo"
              />
            </a>
          </Col>
          <Col
            xs={12}
            sm={9}
            md={10}
            lg={10}
            xl={7}
            xxl={7}
            className="align-self-center spc-sb"
          >
            <SearchBox name={name} min={minPrc} max={maxPrc} />
          </Col>
        </Row>
        <Row>
          <Col></Col>
          <Col sm={12} xl={8} xxl={8} className="">
            <div className="headings">
              <h1>Veja os whiskies que achei:</h1>
            </div>
            <div className="result-column">
              {isLoading ? (
                <CardSkeleton className="result-block text-center" count={20} />
              ) : (
                whiskies?.length > 0 &&
                whiskies.map((wsk) => (
                  <div key={wsk.id} className="result-block">
                    <a
                      href={wsk.url}
                      target="_blank"
                      rel="noreferrer"
                      title={wsk.name.toLowerCase()}
                    >
                      <div className="img-container">
                        <img
                          src={wsk.imgUrl}
                          alt={wsk.name}
                          title={wsk.name}
                          className="product-img"
                        />
                      </div>
                      <p className="name">{wsk.name.toLowerCase()}</p>
                    </a>
                    <div className="shop" title={wsk.store}>
                      {wsk.store}
                    </div>
                    <div className="price">{money.format(wsk.price)}</div>
                  </div>
                ))
              )}
            </div>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Result;
