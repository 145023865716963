import { Col, Container, Row } from "react-bootstrap";
import SearchBox from "../../Components/SearchBox/SearchBox";
import "./Home.css";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";

const Home = () => {
  return (
    <Fragment>
      <Helmet>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Container fluid>
        <Row className="justify-content-center spc-top">
          <Col
            xs={8}
            sm={8}
            md={5}
            lg={5}
            xl={3}
            xxl={3}
            className="text-center"
          >
            <img
              src="./logo.svg"
              alt="Whisky Go"
              title="Whisk Go"
              className="img-fluid logo-home"
            />
            {/* <div className="logotype">whiskygo</div> */}
            <div>
              <img
                src="./logotype.svg"
                alt="Whisky Go"
                title="Whisk Go"
                className="logotype"
              />
            </div>
          </Col>
        </Row>
        <Row className="">
          <Col></Col>
          <Col
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={4}
            xxl={4}
            className="text-center"
          >
            <br></br>
            <SearchBox />
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Home;

// <div>
// <div className="logo-container">
//
//   {/* <div className="brand">Whisky Go</div> */}
// </div>
//
// <div className="footer"></div>
// </div>
