import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Result from "./Pages/Result/Result";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Routes>
        <Route index={true} path="/" element={<Home />} />
        <Route path="/search/:name" element={<Result />} />
        <Route path="/search/price/:rawRange" element={<Result />} />
        <Route path="/search/" element={<Result />} />
      </Routes>
    </BrowserRouter>
  </HelmetProvider>
);
