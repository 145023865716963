import "./SearchBox.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

const SearchBox = (props) => {
  const [text, setText] = useState("");
  const [prcFrom, setPF] = useState("");
  const [prcTo, setPT] = useState("");
  const [showPrice, setShowPrice] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (showPrice) {
      navigate(`/search/price/${prcFrom}-${prcTo}`);
    } else {
      navigate(`/search/${text}`);
    }
  };

  useEffect(() => {
    if (window.location.pathname.includes("price")) {
      setShowPrice(true);
    }
    setText(props.name || "");
    setPF(props.min || "");
    setPT(props.max || "");
  }, [props.name, props.min, props.max]);

  return (
    <div className="flex">
      {!showPrice ? (
        <form className="sb sbc" onSubmit={handleSubmit}>
          <input
            type="text"
            autoFocus
            className="query-input ipt-name"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <button type="submit" className="query-button"></button>
        </form>
      ) : (
        <form className="form sbc" onSubmit={handleSubmit}>
          <div className="sb">
            <input
              type="number"
              autoFocus
              className="query-input ipt-price"
              value={prcFrom}
              onChange={(e) => setPF(e.target.value)}
              placeholder="De"
              pattern="\d*"
            />
          </div>
          <div className="sb ml">
            <input
              type="number"
              className="query-input ipt-price"
              value={prcTo}
              onChange={(e) => setPT(e.target.value)}
              placeholder="Até"
              pattern="\d*"
            />
            <button type="submit" className="query-button query-button-price"></button>
          </div>
        </form>
      )}
      {!showPrice ? (
        <Button
          variant="warning"
          className="btn ml"
          onClick={() => setShowPrice(true)}
        >
          <i className="fa-solid fa-arrow-right-arrow-left"></i> Preço
        </Button>
      ) : (
        <Button
          variant="warning"
          className="btn ml"
          onClick={() => setShowPrice(false)}
        >
          <i className="fa-solid fa-arrow-right-arrow-left"></i> Nome
        </Button>
      )}
    </div>
  );
};

export default SearchBox;
