import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {v4 as uuidv4} from 'uuid';

const CardSkeleton = ({ className, count }) => {
  return (
    <React.Fragment>
      {Array(count)
        .fill(0)
        .map(() => (
          <div className={className} key={uuidv4()}>
            <Skeleton circle={true} width={91} height={91} />
            <br />
            <br />
            <Skeleton count={2} />
            <br />
            <Skeleton count={3} />
          </div>
        ))}
    </React.Fragment>
  );
};

export default CardSkeleton;
